

















import { Vue, Emit, Prop } from 'vue-property-decorator';

export default class DeleteContext extends Vue
{
	@Prop() deletedItemId: number;

	@Emit('close')
	closeDeleteContext()
	{
        return true;
	}

	@Emit('delete')
	deleteItem()
	{
        return true;
	}
}
