
























































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Checkbox from '@/components/ui/checkbox/Checkbox.vue';
import EditListItem from './EditListItem.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import PopupDialogDefault from '@/components/ui/popup-dialog/PopupDialogDefault.vue';
import DotsIcon from '@/assets/images/dots.svg';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue'
import PopupDialog from '@/components/ui/popup-dialog/PopupDialog.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';

@Component({
  name: 'ListItem',
  components: {
    Dropdown,
    PopupDialogDefault,
    Checkbox,
    DeleteContext,
    Icon,
    ContextMenuSpawner,
    EditListItem,
    PopupDialog,
    ContextMenuLink,
    ContextMenuHeader,
  },
})
export default class Item extends Vue {
  $isMobile: boolean;
  dotsIcon = DotsIcon;

  @Prop() driver: string;

  @Prop({ required: true }) name: string;
  @Prop({ required: false }) contextClassName: string;
  @Prop({ required: false }) hideContext: boolean;
  @Prop({ required: false }) hideOpionName: boolean;
  @Prop({ default: false }) checkBoxStatus: boolean;
  @Prop({ default: false }) optsButton: boolean;
  @Prop({ default: false }) checkboxRequired: boolean;

  // Base values
  public showDeleteContext = false;
  public editContextMenuStatus = false;
  public hasInteraction = false;
  public isHovered = false;
  public isEdit = false;
  public isDeleteConfirmOpened = false;
  public isOptionsOpened = false;

  get getName()
  {
    return `${this.name} ?`
  }

  @Emit('editField')
  editField(value: string) {
    this.editContextMenuStatus = false;
    if (this.name === value) {
      return null;
    }
    else {
      return { value: value, id: this.$vnode.key }
    }
  }
  // Getters

  public get showExtraIcon(): boolean {
    return (
      this.$isMobile || this.isHovered || this.isOptionsOpened
      // ((this.isHovered || this.isOptionsOpened) && !this.$isMobile && !!this.$slots.options?.length)
    );
  }
  @Emit('delete')
  deleteField() {
    this.showDeleteContext = false;
    return this.$vnode.key;
  }

  @Emit('checkBoxChanged')
  checkBoxChanged() {
    return this.$vnode.key;
  }
  // Methods

  @Emit('change')
  public editAction(newValue: string) {
    this.isEdit = false;

    return newValue;
  }

  @Emit('delete')
  public deleteAction() {
    this.$parent.$emit('delete', this.name);
    return;
  }

  // Event driven logic

  @Watch('isDeleteConfirmOpened')
  private fixHoverState() {
    if (!this.isDeleteConfirmOpened) this.isHovered = false;
  }

  @Watch('isEdit')
  @Watch('isHovered')
  @Watch('isDeleteConfirmOpened')
  @Watch('isOptionsOpened')
  private setHasInteraction() {
    this.hasInteraction = this.isEdit || this.isDeleteConfirmOpened || this.isOptionsOpened;
  }
}
